<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :details="details"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView, helpers } from '@cargill/shared'
import service from '../api/takeUpImportsPreparationService'
import takeUpImportReportService from '../api/takeUpImportReportService'
import FileDownload from 'js-file-download'

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {}
    }
  },
  methods: {
    async getTakeUpImportReportMeta() {
      const takeUpImportReportMeta =
        await takeUpImportReportService.getMetaWithValidation()
      helpers.processMetaDetails(
        takeUpImportReportMeta,
        'takeUpImportReport',
        'importsPreparationId',
        'takeUpImportsPreparation',
        takeUpImportReportService
      )
      takeUpImportReportMeta.customToolbarActions = [
        {
          canShow: true,
          titleKey: 'controlTower.misc.exportCsv',
          onClick: this.exportCsvDetail,
          iconColor: '#959DB5',
          faIcon: 'fa-file-csv'
        }
      ]
      return takeUpImportReportMeta
    },
    async getMetaDetails() {
      return await Promise.all([this.getTakeUpImportReportMeta()])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMeta(),
        this.getMetaDetails()
      ])
      meta.details = metaDetails
      meta.customToolbarActions = [
        {
          canShow: true,
          titleKey: 'controlTower.misc.exportCsv',
          onClick: () => this.exportCsvMaster(false),
          iconColor: '#959DB5',
          faIcon: 'fa-file-csv'
        },
        {
          canShow: true,
          titleKey: 'controlTower.misc.exportCsvImportInItas',
          onClick: () => this.exportCsvMaster(true),
          iconColor: '#959DB5',
          faIcon: 'fas fa-clipboard-check'
        }
      ]
      return meta
    },
    async exportCsvDetail() {
      const data = await takeUpImportReportService.exportItasCsv(
        takeUpImportReportService.lastAppliedFilter
      )
      this.exportCsv(data)
    },
    async exportCsvMaster(importInItas) {
      const data = await service.exportItasCsv(
        service.lastAppliedFilter,
        importInItas
      )
      this.exportCsv(data)
    },
    exportCsv(data) {
      FileDownload(
        new Blob([data], { type: 'text/csv' }),
        `Imports_Lotes_ITAS.csv`
      )
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta, 'controlTower.pages')
      this.metadata = meta
    })
  }
}
</script>
