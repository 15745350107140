import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService('/api/controltower/takeUpImportReport', api, {
  importLogUrl: '/api/controltower/importLog'
})

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.gin.options = ctx.ginOptions
}

service.exportItasCsv = async (filter) => {
  const baseUrl = '/api/controltower/takeUpImportReport/exportItasCsv'
  const url = filter ? `${baseUrl}?${filter}` : baseUrl
  const response = await api.get(url, {
    responseType: 'text/csv'
  })
  return response?.data
}

export default service
