import { api, createCrudService } from '@cargill/shared'

const service = createCrudService(
  '/api/controltower/takeUpImportsPreparation',
  api,
  { importLogUrl: '/api/controltower/importLog' }
)

service.exportItasCsv = async (filter, importInItas) => {
  const baseUrl = '/api/controltower/takeUpImportsPreparation/exportItasCsv'
  const importInItasQuery = `importInItas=${importInItas}`
  const url = filter
    ? `${baseUrl}?${filter}&${importInItasQuery}`
    : `${baseUrl}?${importInItasQuery}`

  const response = await api.get(url, {
    responseType: 'text/csv'
  })
  return response?.data
}

export default service
